var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// Taken from Reid's answer at http://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
(function () {
  if (!Array.prototype.move) {
    Array.prototype.move = function (old_index, new_index) {
      if ((this || _global).length === 0) {
        return this || _global;
      }

      while (old_index < 0) {
        old_index += (this || _global).length;
      }

      while (new_index < 0) {
        new_index += (this || _global).length;
      }

      if (new_index >= (this || _global).length) {
        var k = new_index - (this || _global).length;

        while (k-- + 1) {
          this.push(undefined);
        }
      }

      this.splice(new_index, 0, this.splice(old_index, 1)[0]);
      return this || _global; // for testing purposes
    };
  }
})(exports);

export default exports;